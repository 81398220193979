import * as React from "react"
import { graphql } from 'gatsby';
import NotFound from 'pages/404';

import Layout from 'components/templates/Layout';
import { FeaturedContentSlider } from "components/templates/global/FeaturedContentSlider";
import TopHalf from "components/templates/neighborhood/TopHalf";
import Crowdriff from "components/templates/global/Crowdriff";
import Neighborhoods from "components/templates/global/Neighborhoods";

import useLiveData from 'hooks/useLiveData';

const Neighborhood = props => {

	const { edges } = props.data.allBeachNeighborhood;
	const nodes = edges.map(({ node }) => node);
	let neighborhood = nodes[0];

	const { statusText: providerStatusText, status: providerStatus, response: providerResponse, visibilityChange } = useLiveData({
		type: 'BeachNeighborhood',
		staticData: props.data,
		apiParams: { uri: props.location.pathname, approved: true, archived: false, deleted: false, _limit: 3, _sort: "sort", _order: "asc", "!featuredIcon": "", _join: "Post" }
	});

	if (providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((providerResponse.data.length > 0 || !!neighborhood !== false) && providerStatusText === 'LOADED') {
		neighborhood = providerResponse.data[0];
	}
	if (providerResponse && visibilityChange) neighborhood = providerResponse.data[0];
	if (!!neighborhood === false && providerStatus >= 2) return <NotFound />;

	const slides = neighborhood?.Post.map(({ object }) => ({ title: object.title, description: object.intro, image: object.photo, link: object.permalink, linkText: "Read More" }))
	
	return (
		<Layout headerBackgroundImage={neighborhood?.headerImage}>
			<section className="neighborhood">
				<TopHalf
					preTitle={neighborhood?.preTitle}
					title={neighborhood?.hoodTitle}
					content={neighborhood?.description}
					activities={neighborhood?.activity}
					photos={neighborhood?.introImages}
				/>

				{(!!neighborhood?.Post && neighborhood?.Post.length) && <FeaturedContentSlider slides={slides} schema={{ side: "left", color: "normal", imageSize: "massive" }} />}

				<Neighborhoods />
				
				{neighborhood?.crowdriff && (
					<Crowdriff hash={neighborhood?.crowdriff} />
				)}

			</section>
		</Layout>
	)
}

export default Neighborhood;

export { Head } from 'components/templates/Head';

export const query = graphql`
query neighborhoodGetQuery ($permalink: String) {
    allBeachNeighborhood(
      filter: {permalink: {eq: $permalink}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
    ) {
      edges {
        node {
          name
          permalink
          preTitle
          hoodTitle
          image
          headerImage
          description
          introImages
          featuredIcon
          featuredDescription
          crowdriff
          activity
		  Post {
            object {
              title
              subtitle
              intro
              photo
            }
          }
        }
      }
    }
}
`